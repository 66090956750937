import {TDifValuesRequest} from "../../types";

const actions = {
    GET_REQUEST: 'GET_REQUEST_DIF',
    GET_SUCCESS: 'GET_SUCCESS_DIF',

    SET_VALUE: 'SET_VALUE_DIF',

    GET_OPTIONS_REQUEST: 'GET_OPTIONS_REQUEST_DIF',
    GET_OPTIONS_SUCCESS: 'GET_OPTIONS_SUCCESS_DIF',

    GET_VALUES_REQUEST: 'GET_VALUES_REQUEST_DIF',
    GET_VALUES_SUCCESS: 'GET_VALUES_SUCCESS_DIF',

    GET_ARTICLE_REQUEST: 'GET_ARTICLE_REQUEST_DIF',
    GET_ARTICLE_SUCCESS: 'GET_ARTICLE_SUCCESS_DIF',

   /* GET_TIPOLOGI_REQUEST: '',*/


    getArticleOptions: (args: {cod_categoria: string, cod_famiglia: string})=> ({
        type: actions.GET_ARTICLE_REQUEST,
        args
    }),
    getValues: (args : TDifValuesRequest) => ({
        type: actions.GET_VALUES_REQUEST,
        args
    }),
    getOptions: () => ({
        type: actions.GET_OPTIONS_REQUEST,

    }),
    getData: (args: object) => ({
        type: actions.GET_REQUEST,
        args
    }),

}

export default actions
