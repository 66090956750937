export default {
    ChooseProduct : 'Choose a product',
    SelectFamily : 'Select the family of products that interest you',
    Cert : 'Our certifications',
    Filtri : 'Filters',
    Diffusori : 'Diffusers',
    Ventilazione : 'Ventilation',
    Aspirazione : 'Aspiration',
    Tubi_industriali : 'Industrial pipes',
    Tubi_di_ventilazione : 'Ventilation pipes',
    subText : 'Check out our certifications',
    title1 : 'Antibacterial',
    title2 : 'Green building',
    title3 : 'Quality',
    coming_soon : 'Coming soon',
    Contatti : 'Contacts',
    subTextContatti : 'Our contacts'

}