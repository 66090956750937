import React from "react";
export function SearchIcon () {
    return <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="#3B383E"/>
    </svg>
}
export function MailIcon () {
    return <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.49992 0H22.4999C22.8093 0 23.1061 0.105357 23.3249 0.292893C23.5437 0.48043 23.6666 0.734784 23.6666 1V17C23.6666 17.2652 23.5437 17.5196 23.3249 17.7071C23.1061 17.8946 22.8093 18 22.4999 18H1.49992C1.1905 18 0.893753 17.8946 0.674961 17.7071C0.456168 17.5196 0.333252 17.2652 0.333252 17V1C0.333252 0.734784 0.456168 0.48043 0.674961 0.292893C0.893753 0.105357 1.1905 0 1.49992 0V0ZM21.3333 4.238L12.0839 11.338L2.66659 4.216V16H21.3333V4.238ZM3.26275 2L12.0711 8.662L20.7523 2H3.26275Z" fill="#3B383E"/>
    </svg>
}
export function UserIcon () {
    return <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z" fill="#3B383E"/>
    </svg>
}
export function BurgerMenu() {
    return <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27" height="4" fill="white"/>
        <rect y="9" width="27" height="4" fill="white"/>
        <rect y="18" width="27" height="4" fill="white"/>
    </svg>
}
export function Close() {
    return <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.511862 0.534255C0.839705 0.20618 1.2843 0.0218782 1.74787 0.0218782C2.21144 0.0218782 2.65603 0.20618 2.98387 0.534255L10.4891 8.047L17.9943 0.534255C18.1555 0.367112 18.3484 0.233793 18.5617 0.142077C18.775 0.0503613 19.0044 0.00208525 19.2366 6.60748e-05C19.4687 -0.0019531 19.6989 0.0423245 19.9138 0.130316C20.1286 0.218308 20.3238 0.348251 20.488 0.512563C20.6521 0.676875 20.7819 0.872266 20.8698 1.08733C20.9577 1.3024 21.002 1.53284 20.9999 1.76521C20.9979 1.99757 20.9497 2.22721 20.8581 2.44071C20.7664 2.65422 20.6333 2.84732 20.4663 3.00875L12.9611 10.5215L20.4663 18.0343C20.7847 18.3643 20.961 18.8064 20.957 19.2652C20.953 19.7241 20.7691 20.163 20.445 20.4874C20.1208 20.8119 19.6824 20.9959 19.224 20.9999C18.7656 21.0039 18.324 20.8275 17.9943 20.5088L10.4891 12.996L2.98387 20.5088C2.65415 20.8275 2.21254 21.0039 1.75416 20.9999C1.29578 20.9959 0.857295 20.8119 0.533157 20.4874C0.209019 20.163 0.0251582 19.7241 0.0211749 19.2652C0.0171917 18.8064 0.193405 18.3643 0.511862 18.0343L8.01706 10.5215L0.511862 3.00875C0.184117 2.68058 0 2.23554 0 1.7715C0 1.30747 0.184117 0.862428 0.511862 0.534255Z" fill="white"/>
    </svg>
}
export function Border({type}:{ type: 'top' | 'bottom'}) {
    return type === 'top' ? <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="32" width="5" height="30" transform="rotate(-180 5 32)" fill="#E31014"/>
        <rect x="30" width="5" height="30" transform="rotate(90 30 0)" fill="#E31014"/>
    </svg> : <>
        <svg width="30" height="5" viewBox="0 0 30 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="5" width="5" height="30" transform="rotate(-90 0 5)" fill="#E31014"/>
        </svg>
        <svg width="5" height="30" viewBox="0 0 5 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="5" height="30" fill="#E31014"/>
        </svg>
    </>
}
export function Tel() {
    return <svg style={{verticalAlign:'middle'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H3.153C3.38971 0.000108969 3.6187 0.0841807 3.79924 0.23726C3.97979 0.390339 4.10018 0.602499 4.139 0.836L4.879 5.271C4.91436 5.48222 4.88097 5.69921 4.78376 5.89003C4.68655 6.08085 4.53065 6.23543 4.339 6.331L2.791 7.104C3.34611 8.47965 4.17283 9.72928 5.22178 10.7782C6.27072 11.8272 7.52035 12.6539 8.896 13.209L9.67 11.661C9.76552 11.4695 9.91994 11.3138 10.1106 11.2166C10.3012 11.1194 10.5179 11.0859 10.729 11.121L15.164 11.861C15.3975 11.8998 15.6097 12.0202 15.7627 12.2008C15.9158 12.3813 15.9999 12.6103 16 12.847V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H13C5.82 16 0 10.18 0 3V1Z" fill="black"/>
    </svg>
}

export function GoBack() {
    return <svg style={{verticalAlign:'middle'}} width="55" height="27" viewBox="0 0 55 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 13.5H53M13.3333 25L2 13.5L13.3333 25ZM2 13.5L13.3333 2L2 13.5Z" stroke="#E31014" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export function SelectDropdownIcon({className}: {className?: string}) {
    return <svg width="17" height="15" className={className} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 15L0.27276 0.749998L16.7272 0.75L8.5 15Z" fill="#3B383E"/>
    </svg>
}
export function Filter() {
    return <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill="#3B383E"/>
    </svg>
}
export function WatchIcon() {
    return <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 7C1.274 2.943 5.064 0 9.542 0C14.02 0 17.81 2.943 19.084 7C17.81 11.057 14.02 14 9.542 14C5.064 14 1.274 11.057 0 7ZM13.542 7C13.542 8.06087 13.1206 9.07828 12.3704 9.82843C11.6203 10.5786 10.6029 11 9.542 11C8.48113 11 7.46372 10.5786 6.71357 9.82843C5.96343 9.07828 5.542 8.06087 5.542 7C5.542 5.93913 5.96343 4.92172 6.71357 4.17157C7.46372 3.42143 8.48113 3 9.542 3C10.6029 3 11.6203 3.42143 12.3704 4.17157C13.1206 4.92172 13.542 5.93913 13.542 7Z" fill="#3B383E"/>
    </svg>
}
export function CloseIcon() {
    return <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.23008 1.2301C1.63326 0.827038 2.18002 0.600609 2.75013 0.600609C3.32023 0.600609 3.86699 0.827038 4.27018 1.2301L13.5001 10.4601L22.7301 1.2301C22.9284 1.02475 23.1656 0.860962 23.428 0.748282C23.6903 0.635603 23.9724 0.576292 24.2579 0.573812C24.5433 0.571331 24.8265 0.625729 25.0907 0.733833C25.3549 0.841937 25.595 1.00158 25.7968 1.20345C25.9987 1.40532 26.1583 1.64537 26.2664 1.9096C26.3745 2.17383 26.4289 2.45694 26.4265 2.74241C26.424 3.02789 26.3647 3.31001 26.252 3.57232C26.1393 3.83463 25.9755 4.07187 25.7702 4.2702L16.5402 13.5001L25.7702 22.7301C26.1618 23.1356 26.3785 23.6787 26.3736 24.2424C26.3687 24.8061 26.1426 25.3454 25.744 25.744C25.3454 26.1426 24.8061 26.3687 24.2424 26.3736C23.6787 26.3785 23.1356 26.1618 22.7301 25.7702L13.5001 16.5402L4.27018 25.7702C3.86468 26.1618 3.32159 26.3785 2.75786 26.3736C2.19414 26.3687 1.65489 26.1426 1.25627 25.744C0.857639 25.3454 0.631526 24.8061 0.626627 24.2424C0.621728 23.6787 0.838437 23.1356 1.23008 22.7301L10.46 13.5001L1.23008 4.2702C0.827014 3.86701 0.600586 3.32025 0.600586 2.75015C0.600586 2.18005 0.827014 1.63329 1.23008 1.2301Z" fill="#3B383E"/>
    </svg>
}
export function MailSvg() {
    return <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.16667 0H22.1667C22.4761 0 22.7728 0.105357 22.9916 0.292893C23.2104 0.48043 23.3333 0.734784 23.3333 1V17C23.3333 17.2652 23.2104 17.5196 22.9916 17.7071C22.7728 17.8946 22.4761 18 22.1667 18H1.16667C0.857247 18 0.560501 17.8946 0.341709 17.7071C0.122916 17.5196 0 17.2652 0 17V1C0 0.734784 0.122916 0.48043 0.341709 0.292893C0.560501 0.105357 0.857247 0 1.16667 0ZM21 4.238L11.7507 11.338L2.33333 4.216V16H21V4.238ZM2.9295 2L11.7378 8.662L20.419 2H2.9295Z" fill="black"/>
    </svg>
}
export function CollapseIcon() {
    return <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 0L16.7272 14.25L0.272758 14.25L8.5 0Z" fill="white"/>
    </svg>
}
/*
export function Search() {
    return <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="black"/>
    </svg>

}*/
