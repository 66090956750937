import * as jsEn from './Components/lang/en/js'
import * as jsIt from './Components/lang/it/js';
import * as commonEn from './Components/lang/en/common';
import * as commonIt from './Components/lang/it/common';
import * as passwordIt from "./Components/lang/it/password";
import * as passwordEn from "./Components/lang/en/password";


export default {
    en: {
       js: {
           ...jsEn.default
       },
        common:{
           ...commonEn.default
        },
        password:{
           ...passwordEn.default
        }
    },
    it: {
        js: {
            ...jsIt.default
        },
        common:{
            ...commonIt.default
        },
        password: {
            ...passwordIt.default
        }
    }
}