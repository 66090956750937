import actions from './actions';
import {IAction, TBar, TDiametroOpt, TFilters, TTubi} from "../../types";

type select = {
    label: string,
    value: string
}

const initialState = {
    data: [],
    firstLoad: false,
    propertyOpt: [],
    fagmiliaOpt: [],
    reazioneOpt: [],
    temperatureOpt: [],
    diametroOpt: [],
    autocompleteList: [],
    reazion: {},
    bar: [],
    modelo: [],
    isFetching: false
};
export type TAspirationState = {
    data: TTubi[],
    firstLoad: boolean,
    isFetching: boolean,
    propertyOpt: select[],
    fagmiliaOpt: select[],
    reazioneOpt: select[],
    temperatureOpt: select[],
    diametroOpt: TDiametroOpt[],
    modelo: object[],
    bar: TBar[],
    reazion: {
        classe: string,
        cod_classe: string,
        cod_normativa: string,
        normativa: string,
        note_classe: string,
        note_normativa: string
    },
    autocompleteList: {
        cod_agente: string,
        agente: string,
        note_agente: string,
        resistenza: 'A'|'B'|'C'
    }[]
}
export default function aspiration(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {
        case actions.GET_MODELO_SUCCESS:
            return {
                ...state,
                modelo: data
            }
        case actions.GET_BAR_VALUES_SUCCESS:
            return {
                ...state,
                bar: data
            }
        case actions.GET_REAZION_VALUE_SUCCESS:
            return {
                ...state,
                reazion: Array.isArray(data) ? data[0] : {}
            }
        case actions.GET_AUTOCOMPLETE_LIST_SUCCESS:
            return {
                ...state,
                autocompleteList: data
            }
        case actions.GET_DIAMETR_OPTIONS_SUCCESS:
            return {
                ...state,
                diametroOpt: data
            }
        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            }
        case actions.GET_PROPERTY_OPTIONS_SUCCESS:
            return {
                ...state,
                propertyOpt: Array.isArray(data) ? data.map(item=> {return {label: item.proprieta, value: item.cod_proprieta}}) : []
            }
        case actions.GET_FAGMILIA_OPTIONS_SUCCESS:
            return {
                ...state,//cod_proprieta
                fagmiliaOpt: Array.isArray(data) ? data.map(item=> {return {label: item.famiglia, value: item.cod_famiglia}}) : []
            }
        case actions.GET_REAZIONE_OPTIONS_SUCCESS:
            return {
                ...state,
                reazioneOpt: Array.isArray(data) ? data.map(item=> {return {label: item.normativa, value: item.cod_normativa}}) : []
            }
        case actions.GET_TEMPERATURE_OPTIONS_SUCCESS:
            return {
                ...state,
                temperatureOpt: Array.isArray(data) ? data.map(item=> {return {label: item.temperatura, value: item.cod_temperatura}}) : []
            }
        case actions.GET_SUCCESS:
            return {
                ...state,
                firstLoad: false,
                data: Array.isArray(data) ? data.map(item=> { return {...item, img: `data:image/png;base64, ${item.img}`}}) : []
            };
        default:
            return state;
    }
}
