import actions from './actions';
import {IAction, TDiffusers} from "../../types";


const initialState = {
    data: [],
    options: [],
    firstLoad: false,
    article: [],
    values: [],
    valuesLoading: false
};
export type TDiffusersState = {
    data: TDiffusers[],
    options: {
      label: string,
      value: string
    }[],
    article: {
        label: string,
        value: string,
        img: string
    }[],
    firstLoad: boolean,
    values: object[],
    valuesLoading: boolean
}
export default function dif(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {
        case actions.GET_VALUES_SUCCESS:
            return {
                ...state,
                values: Array.isArray(data) && data.length > 0 ? data : [],
                valuesLoading: false
            }
        case actions.GET_ARTICLE_SUCCESS:
            return {
                ...state,
                article: Array.isArray(data) ? data.map(item=> { return {value: item.cod_articolo, label: item.articolo, img: item.img}}) : []
            }
        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            }
        case actions.GET_SUCCESS:
            return {
                ...state,
                data: Array.isArray(data) ? data.map(item=> { return {...item, img: `data:image/png;base64, ${item.img}`}}) : [],
                firstLoad: false
            };
        case actions.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: Array.isArray(data) ? data.map(item => {return {label: item.famiglia, value: item.cod_famiglia}}) : []
            };
        default:
            return state;
    }
}
