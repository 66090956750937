import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";

function* login({ args }) {
    try {
        const { email, password } = args;

        yield put({ type: actions.SET_VALUE, data: { isFetching: true, loginError: false }});
        const data = yield service.login(email, password);

        localStorage.setItem('token', data.data.user.token);

        const user =  yield service.getMe();
        
        yield put({ type: actions.LOGIN_SUCCESS, ...data });
        yield put({type: actions.GET_ME_SUCCESS, data: user.data.data.user})

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { isFetching: false, loginError: true } })
    }
}

function* registration ({ args }) {
    try {

        yield put({ type: actions.SET_VALUE, data: { isFetching: true, registrationError: null } })
        const data = yield service.registration(args);
        if(data.data.success) yield put({ type: actions.REGISTRATION_SUCCESS, ...data });
        else yield put({ type: actions.SET_VALUE, data: { isFetching: false, registrationError: data.data.error } })

    }catch (e) {
        yield put({ type: actions.SET_VALUE, data: { isFetching: false, registrationError: true } })
    }
}
function* passwordReset({ args }) {
    try {
        yield put({ type: actions.SET_VALUE, data: { isFetching: true } })
        const data = yield service.passwordRec(args);
        yield put({ type: actions.PASS_RESET_SUCCESS, ...data });

    }catch (e) {
        yield put({ type: actions.SET_VALUE, data: { isFetching: false } })
    }
}

function* getMe() {
    try {

        yield put( { type: actions.SET_VALUE, data: { isUserLoading: true } })
        const data = yield service.getMe();
        yield put({type: actions.GET_ME_SUCCESS,  data: data.data.data.user});

    }catch (e) {
        yield put( { type: actions.SET_VALUE, data: { isUserLoading: false } })
    }
}
function* logout() {
    try {

        localStorage.setItem('token', null);
        yield put( { type: actions.LOGOUT_SUCCESS, data: null });
        //const data = yield service.logout();
        //yield put( { type: actions.LOGOUT_SUCCESS, data: data.data });

    }catch (e) {
        console.log(e)
    }
}
function* editMe({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { isFetching: true}});
        const data = yield service.editMe(args);
        yield put({type: actions.EDIT_ME_SUCCESS, data:data.data.data});
        yield put({type: actions.SET_VALUE, data: { isFetching: false}});

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { isFetching: false}})
    }
}
function* newPassword({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { isFetching: true}});
        const data = yield service.passwordRenew(args.new_password);
        yield put({type: actions.NEW_PASSWORD_SUCCESS, data: data.data});


    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { isFetching: false}})
    }
}
export default function* authSaga() {
    yield all([
        takeEvery(actions.NEW_PASSWORD_REQUEST, newPassword),
        takeEvery(actions.EDIT_ME_REQUEST, editMe),
        takeEvery(actions.LOGOUT_REQUEST, logout),
        takeEvery(actions.GET_ME_REQUEST, getMe),
        takeEvery(actions.PASS_RESET_REQUEST, passwordReset),
        takeEvery(actions.REGISTRATION_REQUEST, registration),
        takeEvery(actions.LOGIN_REQUEST, login)
    ]);
}
