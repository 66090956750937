import {TEditMeRequest, TRegistration} from "../../types";

const actions = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',


    SET_VALUE: 'SET_VALUE',

    REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',

    PASS_RESET_REQUEST: 'PASS_RESET_REQUEST',
    PASS_RESET_SUCCESS: 'PASS_RESET_SUCCESS',

    GET_ME_REQUEST: 'GET_ME_REQUEST',
    GET_ME_SUCCESS: 'GET_ME_SUCCESS',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

    EDIT_ME_REQUEST: 'EDIT_ME_REQUEST',
    EDIT_ME_SUCCESS: 'EDIT_ME_SUCCESS',

    NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
    NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',

    SET_LANG:'SET_LANG',

    setNewPassword: (args: {new_password: string}) => ({
        type: actions.NEW_PASSWORD_REQUEST,
        args
    }),
    setLang: (data: 'en' | 'it')=>({
        type:actions.SET_LANG,
        data
    }),
    editMe: (args: TEditMeRequest)=>({
        type: actions.EDIT_ME_REQUEST,
        args
    }),
    logout: ()=>({
        type: actions.LOGOUT_REQUEST,
        args: null
    }),
    getMe: () => ({
        type: actions.GET_ME_REQUEST,
        args: null
    }),
    passwordReset: (args: string) => ({
        type: actions.PASS_RESET_REQUEST,
        args
    }),
    registration: (args : TRegistration)=>({
        type: actions.REGISTRATION_REQUEST,
        args
    }),
    login: (args: { email: string, password: string})=>({
        type: actions.LOGIN_REQUEST,
        args
    })
}

export default actions
