export default {
    SaveBtn : 'SALVA',
    ClickHere : 'Premi qui',
    ChangePass : 'Vuoi cambiare la password?',
    ForgotPass : 'Hai perso la password?',
    Name : 'Nome',
    Prodotti: 'Prodotti',
    Profilo: 'Profilo personale',
    Contatti: 'Contatti',
    Submit: 'INVIA',
    Esci: 'Esci',
    Home: 'Home',
    pasRes: 'Password dimenticata ?',
    registrationRef: 'Registrati subito',
    logOr: 'Accedi al tuo account oppure',
    SelezionaCertificazione: 'Seleziona la certificazione Green Building',
    goBack: 'TORNA INDIETRO',
    App: 'App Tecnica',
    Surname : 'Cognome',
    Address : 'Indirizzo',
    Company : 'Azienda',

    wrong:'Password o email errate!',
    isRequired: ' è obbligatorio',

    SalesOffice: 'Ufficio vendite Italia',
    ContactsTubi: ' Divisione Tubi Flessibili',
    ContactsFiltriDiff: 'Divisione Filtri e Diffusori',
    ComOffice: 'Ufficio commerciale',
    FSalesOffice: 'Ufficio vendite estero',
    Abroad: 'Abroad',
    Italy: 'Italia',
    PDep: 'Ufficio acquisti',
    Admin: 'Amministrazione',
    Abrasione: 'Abrasione',
    passwordResetPageTitle: 'Recupera password',
    passwordResetFormTitle: 'Hai perso la password ? oppure',
    passwordResetLink: 'Torna indietro',
    registrationFormTitle: 'Effettua una nuova registrazione oppure',
    registrationFromLink: 'Accedi al tuo account',
    profileFormTitle: 'Vuoi cambiare la tua password?',
    profileFormLink: 'Premi qui',

    passwordRenewTitle: 'Aggiorna password',
    passwordRenewTitleOfPage: 'Vuoi modificare il tuo profilo?',

    selectOptions: 'Tutte le caratteristiche',
    selectCert: 'Tutte le certificazioni',
    selectTemperature: 'Seleziona temperatura',
    selectSubstances: 'Tutte le sostanze',
    selectProperties: 'Tutte le proprietà',
    selectMultiple: 'Tutte le selezioni',

    City : 'Città',
    Country : 'Paese',
    LogOut : 'Disconnettersi',
    Back : 'Indietro',
    Close : 'Chiudi',
    Registration : 'REGISTRATI',
    GetAccess : 'ACCEDI',
    CreateAcc : 'Crea Account',
    PassSent : 'Ti è stata inviata una mail alla tua casella di posta. Segui le istruzioni per cambiare password.',
    PassChanged : 'La tua password è stata modificata correttamente.',
    Confirm : 'Conferma password',
    OkPass : 'Le password corrispondono',
    DiffPass : 'Le password non corrispondono',
    EmptyFields : 'Tutti i campi devono essere compilati',
    Logout : 'Logout',
    Fumi: 'Fumi Industriali',
    Certificazioni_menu:'Certificazioni',
    RenewSuccess : 'La registrazione è andata a buon fine!',
    SelectChar : 'Seleziona le caratteristiche del prodotto che cerchi',
    SelectCert : 'Seleziona le certificazioni del prodotto',
    CertTitle : 'Certificazioni green building',
    CertText : 'Per ulteriori informazioini in merito alle specifiche categorie di contribuzione contattare Tecnica srl',
    SanificazioneFiltri : 'Filtri Sanificati',
    SanificazioneTubi : 'Tubi Sanificati',
    Risultati : 'Risultati',
    Filtra: 'Filtra',
    CercaInput : 'Cerca per codice o descrizione..',
    Famiglia : 'Famiglia',
    AddProd : 'Potrebbero interessarti',
    DataList: 'Scheda tecnica',
    SelChar : 'Seleziona le caratteristiche del prodotto',
    Dimensioni : 'Dimensioni nominale',
   // Portata : 'Portata richiestra [m3/h]',
    Diff : 'Differenziale Temperatura',
    Velocit : 'Velocità terminale del lancio [m/s]',
    Altezza : 'Altezza di installazione [m]',
    Vedi : 'VEDI SCHEDA',
    LoadingChar : 'Loading caratteristiche..',
    Caratteristiche : 'Caratteristiche',
    Codice : 'Codice',
    Descrizione : 'Descrizione',
    Sanificazione : 'Sanificazione',
    Diametro : 'Diametro interno [mm]',
    Velocita : 'Velocità flusso aria [m/s]',
    Curva_first_part : 'Curva 90',
    Curva_second_part :  '[R/D]',
    Curva2_first_part : 'Curva 45',
    AdvFiltri : 'Verifica rischio condensa',
    TempFlusso : 'Temperatura flusso aria',
    TempAmbiente : 'Temperatura ambiente',
    Umidita : 'Umidità relativa [%]',
    VelocitaName: 'Velocità flusso aria',
    Misura: 'U. MISURA',
    UMisura: 'MISURA',

    Portata : 'Portata',
    PerditaLineare : 'Perdita di carico lineare',
    PerditaCurva45 : 'Perdita di carico curva 45°',
    PerditaCurva90 : 'Perdita di carico curva 90°',
    Trasmittanza : 'Trasmittanza termica',
    Spessore : 'Spessore isolamento',

    SendEmail: 'INVIA SCHEDA VIA EMAIL',
    SendEmailDescription: 'Inserisci l’indirizzo email su cui vuoi ricevere la scheda ',
    SendEmailConfirmBtn: 'INVIA SCHEDA VIA EMAIL',

    Verifica : 'Verifica rischio condensa',
    TemperaturaEst : 'Temperatura esterna parete tubo [Tpe]',
    TemperaturaMin : 'Temperatura di rugiada [Tdew]',
    TemperaturaMax : 'Temperatura di gocciolamento [Tdrp]',

    Gocciolamento : 'Rischio gocciolamento',
    Condensa : 'Rischio condensa',
    NoCondensa :' No condensa',
    Tutte: 'Tutte le selezioni',
    SostanzaTrattata : 'Sostanza trattata',
    Temperatura : 'Temperatura ',
    Certificazioni : 'Certificazioni',
    Propriet : 'Proprietà',
    Reazione : 'Reazione al fuoco',
    Facoltativo : '(* facoltativo)',
    GreenBuilding : 'Green Building',

    DiametroEmpty : 'Seleziona un diametro per vederi i dati tecnici',
    DiametroInt : 'Diametro interno selezionato',
    TempMin : 'Temperatura minima di esercizio',
    TempMax : 'Temperatura massima di esercizio',
    PiccoMax : 'Picco massimo di temperatura',
    PressioneLabel: 'Pressione',
    DataTech : 'Dati tecnici',
    Resistenza : 'Resistenza agli agenti chimici',
    Pressione : 'Pressione di esercizio',
    Depressione : 'Depressione di esercizio',
    Curvatura : 'Raggio di curvatura',
    Peso : 'Peso',
    Compressione : 'Compressione assiale',
    DepressioneLabel: 'Depressione',
    Norma : 'Norme di referimento / classe',
    LoadingResist : 'Loading resistenza..',
    SearchResist : 'Cerca il valore di ...',

    OldPassword : 'Vecchia password',
    NewPassword : 'Nuova password',

}