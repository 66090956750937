import React, {lazy, Suspense, Fragment, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {  Switch, Route, useHistory} from "react-router-dom";
import {Button, Layout, Spin} from "antd";
import possibleRoutes from './possibleRoutes';
import Navigation from "../Assets/Navigation";
import actions from "../redux/auth/actions";
import {TRootState} from "../redux/store";
import {TAuthState} from "../redux/auth/reducer";
import ContentContainer from './Views/ContainerTemplate'

const { Content } = Layout;


const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', position: 'fixed', height: '50%', width: '100%', bottom: 0, fontSize: '22px' }}><span style={{fontWeight:'bold'}}>{'404 '}</span>This page could not be found</div>
  )
}


export default function LayoutContainer ()  {

    const dispatch = useDispatch()
    const { user, isSuccessAuth, isLogout, isUserLoading } = useSelector<TRootState, TAuthState>(state => state.auth);
    const history = useHistory();

    const data = { role: 'customer' }, firstLoad = false;
    const getMe = useCallback(()=> dispatch(actions.getMe()),[dispatch]);
    const lang = localStorage.getItem('lang')



    useEffect(()=> {
        getMe();
        dispatch(actions.setLang(lang && (lang === 'en' || lang === 'it') ? lang : 'it'))
    } , [isSuccessAuth]);

    const isSigned = !!(user&&user.email);


    const isRedirect = () => !isSigned && isLogout ?  history.push('/') : null;
    isRedirect();


    const routes = possibleRoutes(isSigned).map((route, index) =>
        <Route key={index} exact path={`/${route.path}`} component={route.component}/>
    );


    return (
        <ContentContainer>
            <>
                {
                    isUserLoading
                        ?
                        <Spin className={'loading-spinner'} size="large"  />
                        :
                        <Layout style={{background: "white"}} className="layout">
                            <Content className='has-header-fixed'>
                                <Navigation isAuth={isSigned}  />

                                    <Suspense fallback={<Spin className={'loading-spinner'} size="large"/>}>
                                        <Switch>
                                            {routes}
                                            <Route component={NotFound} />
                                        </Switch>
                                    </Suspense>

                            </Content>
                        </Layout>
                }
        </>
        </ContentContainer>
  );
};
