const actions = {
    GET_REQUEST: 'GET_REQUEST_ASP',
    GET_SUCCESS: 'GET_SUCCESS_ASP',

    GET_FAGMILIA_OPTIONS_REQUEST: 'GET_FAGMILIA_OPTIONS_REQUEST',
    GET_FAGMILIA_OPTIONS_SUCCESS: 'GET_FAGMILIA_OPTIONS_SUCCESS_ASP',

    GET_TEMPERATURE_OPTIONS_REQUEST: 'GET_TEMPERATURE_OPTIONS_REQUEST',
    GET_TEMPERATURE_OPTIONS_SUCCESS: 'GET_TEMPERATURE_OPTIONS_SUCCESS',

    GET_REAZIONE_OPTIONS_REQUEST: 'GET_REAZIONE_OPTIONS_REQUEST',
    GET_REAZIONE_OPTIONS_SUCCESS: 'GET_REAZIONE_OPTIONS_SUCCESS',

    GET_PROPERTY_OPTIONS_REQUEST: 'GET_PROPERTY_OPTIONS_REQUEST',
    GET_PROPERTY_OPTIONS_SUCCESS: 'GET_PROPERTY_OPTIONS_SUCCESS',

    GET_AUTOCOMPLETE_LIST_REQUEST: 'GET_AUTOCOMPLETE_LIST_REQUEST',
    GET_AUTOCOMPLETE_LIST_SUCCESS: 'GET_AUTOCOMPLETE_LIST_SUCCESS',

    GET_DIAMETR_OPTIONS_REQUEST: 'GET_DIAMETR_OPTIONS_REQUEST_ASP',
    GET_DIAMETR_OPTIONS_SUCCESS: 'GET_DIAMETR_OPTIONS_SUCCESS_ASP',

    GET_REAZION_VALUE_REQUEST: 'GET_REAZION_VALUE_REQUEST',
    GET_REAZION_VALUE_SUCCESS: 'GET_REAZION_VALUE_SUCCESS',

    GET_BAR_VALUES_REQUES: 'GET_BAR_VALUES_REQUES',
    GET_BAR_VALUES_SUCCESS: 'GET_BAR_VALUES_SUCCESS',

    SET_VALUE: 'SET_VALUE_ASP',

    GET_MODELO_REQUEST: "GET_MODELO_REQUEST",
    GET_MODELO_SUCCESS: "GET_MODELO_SUCCESS",


    getModelo: () =>({
        type: actions.GET_MODELO_REQUEST,
    }),
    getBar: () => ({
      type: actions.GET_BAR_VALUES_REQUES
    }),
    getReazioneValue: (args: {cod_categoria: string})=>({
        type: actions.GET_REAZION_VALUE_REQUEST,
        args
    }),
    getDiameterOptions: (args: {cod_categoria: string}) => ({
        type: actions.GET_DIAMETR_OPTIONS_REQUEST,
        args
    }),
    getAutoCompleteList: (args: {cod_categoria: string})=> ({
        type: actions.GET_AUTOCOMPLETE_LIST_REQUEST,
        args
    }),
    getOptionsProperty: () => ({
        type: actions.GET_PROPERTY_OPTIONS_REQUEST
    }),
    getOptionsTemp: () => ({
        type: actions.GET_TEMPERATURE_OPTIONS_REQUEST
    }),
    getOptionsReaz: () => ({
        type: actions.GET_REAZIONE_OPTIONS_REQUEST
    }),
    getOptionsFagm: () => ({
        type: actions.GET_FAGMILIA_OPTIONS_REQUEST
    }),

    getData: (args: object) => ({
        type: actions.GET_REQUEST,
        args
    }),

}

export default actions
