import { all } from 'redux-saga/effects';
import authSaga from "./auth/saga";
import tubiSaga from "./tubi/saga";
import difSaga from "./diffusers/saga";
import filtersSaga from "./filters/saga";
import aspSaga from "./аspiration/saga";
import infoSaga from './info/saga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        tubiSaga(),
        difSaga(),
        filtersSaga(),
        aspSaga(),
        infoSaga()
    ]);
}
