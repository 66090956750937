import actions from './actions';
import {IAction, TTubi, TTubiValuesResponse} from "../../types";

const initialState = {
    data: [],
    options: [],
    article: [],
    values: {},
    firstLoad: false,
    valuesLoading: false

};
export type TTubiState = {
    data: TTubi[],
    options: {label: string, value: string}[],
    article: {label: string, value: string}[],
    values: TTubiValuesResponse,
    firstLoad: boolean,
    valuesLoading: boolean
}
export default function tubi(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {
        case actions.GET_ARTICLE_SUCCESS:
            return {
                ...state,
                article: Array.isArray(data) ? data.map(item=> { return {label: item.diametro, value: item.id_articolo}}) : []
            }
        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            }
        case actions.GET_RISK_VALUES_SUCCESS:
            return {
                ...state,
                values: Array.isArray(data) && data.length > 0 ?  data[0] : {},
                valuesLoading: false
            }
        case actions.GET_SUCCESS:
            return {
                ...state,
                data: Array.isArray(data) ? data.map(item=> { return {...item, img: `data:image/png;base64, ${item.img}`}}) : [],
                firstLoad: false
            };
        case actions.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: Array.isArray(data) ? data.map(item=> { return {value: item.cod_famiglia, label: item.famiglia}}) : []
            };
        default:
            return state;
    }
}
