import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";

function* sendEmail({ args }) {
    try {
        yield put({type: actions.SET_VALUE, data: { isFetching: true }})
        const data = yield service.sendEmail(args);
        yield put({type: actions.SEND_EMAIL_SUCCESS, ...data.data});

    }catch (e) {
        console.error(e);
        yield put({type: actions.SET_VALUE, data: { isFetching: false }})
    }
}
function* getCert({ args }) {
    try {

        const data = yield service.getCertification();
        yield put({ type: actions.GET_CERTIFICATIONS_SUCCESS, data: data.data });

    }catch (e) {
        console.log(e);
    }
}
export default function* infoSaga() {
    yield all([
        takeEvery(actions.SEND_EMAIL_REQUEST, sendEmail),
        takeEvery(actions.GET_CERTIFICATIONS_REQUEST, getCert)
    ]);
}
