import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";


function* getData({ args }) {
    try {
        yield put({type: actions.SET_VALUE, data: { firstLoad: true }})
        const data = yield service.getDataDiff(args);
        yield put({ type: actions.GET_SUCCESS, ...data.data });

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { firstLoad: false }})
    }
}
function* getOptions({ args }) {
    try {

        const data = yield service.getOptionsDiff();
        yield put({ type: actions.GET_OPTIONS_SUCCESS, ...data.data });

    }catch (e) {
        console.log(e);
    }
}
function* getValues({ args }) {
    try {
        yield put({type: actions.SET_VALUE, data: { valuesLoading: true }})
        const data = yield service.getValuesDiff(args);
        yield put({ type: actions.GET_VALUES_SUCCESS, ...data });

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { valuesLoading: false }})
        console.log(e);
    }
}
function* getArticle({ args }) {
    try {

        const data = yield service.getArticulDiff(args);
        yield put({ type: actions.GET_ARTICLE_SUCCESS, ...data.data });

    }catch (e) {
        console.log(e);
    }
}
export default function* difSaga() {
    yield all([
        takeEvery(actions.GET_ARTICLE_REQUEST, getArticle),
        takeEvery(actions.GET_VALUES_REQUEST, getValues),
        takeEvery(actions.GET_REQUEST, getData),
        takeEvery(actions.GET_OPTIONS_REQUEST, getOptions)
    ]);
}
