export default {
    ChooseProduct : 'Scegli un prodotto',
    SelectFamily : 'Seleziona la famiglia di prodotti che ti interessano',
    Cert : 'Le nostre certificazioni',
    Filtri : 'Filtri',
    Diffusori : 'Diffusori',
    Ventilazione : 'Ventilazione',
    Aspirazione : 'Aspirazione',
    Tubi_industriali : 'Tubi industriali',
    Tubi_di_ventilazione : 'Tubi ventilazione',
    subText : 'Controlla le nostre certificazioni',
    title1 : 'Antibatterico',
    title2 : 'Green building',
    title3 : 'Qualità',
    coming_soon : 'Prossimamente',
    Contatti : 'Contatti',
    subTextContatti : 'I nostri contatti',

}