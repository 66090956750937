import {TSandEmailRequest} from "../../types";

const actions = {
    GET_CERTIFICATIONS_REQUEST: 'GET_CERTIFICATIONS_REQUEST',
    GET_CERTIFICATIONS_SUCCESS: 'GET_CERTIFICATIONS_SUCCESS',

    SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',

    SET_VALUE: 'SET_VALUE_INFO',


    sendEmail: (args: TSandEmailRequest) => ({
        type: actions.SEND_EMAIL_REQUEST,
        args
    }),
    getCertifications: () => ({
        type: actions.GET_CERTIFICATIONS_REQUEST
    })

}

export default actions
