import actions from './actions';
import {IAction} from "../../types";

export type TAuthState = {
    data: object,
    user: {
        email?: string,
        name?: string,
        city?: string,
        company?: string,
        country?: string
    },
    registrationError: null | string,
    loginError: boolean,
    firstLoad: boolean,
    isFetching: boolean,
    isSuccessAuth: boolean,
    isSuccessReg: boolean,
    passwordResSuccess: boolean,
    isLogout: boolean,
    isEditMeSuccess: boolean,
    isPassNewSuccess: boolean,
    lang: 'en' | 'it',
    isUserLoading: boolean
}

const initialState = {
    data: {},
    user: {},
    firstLoad: true,
    isFetching: false,
    isSuccessAuth: false,
    isSuccessReg: false,
    passwordResSuccess: false,
    isLogout: false,
    isEditMeSuccess: false,
    isPassNewSuccess: false,
    lang: 'en',
    isUserLoading: true,
    loginError: false,
    registrationError: null
};

export default function auth(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {
        case actions.NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isPassNewSuccess: true
            }
        case actions.SET_LANG:
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            typeof data === 'string' && (data === 'en' || data === 'it') ?  localStorage.setItem('lang', data) : null;
            return {
                ...state,
                lang: data
            }
        case actions.EDIT_ME_SUCCESS:
            return {
                ...state,
                user: { ...state.user, ...data },
                isEditMeSuccess: true
            }
        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                isSuccessAuth: false,
                isLogout: true,
                user: {}
            }
        case actions.GET_ME_SUCCESS:
            return {
                ...state,
                user: data,
                isUserLoading: false
            }
        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            };
        case actions.PASS_RESET_SUCCESS:
            return {
                ...state,
                passwordResSuccess: true,
                isFetching: false
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                isSuccessAuth: true,
                isFetching: false,
                isLogout: false
            };
        case actions.REGISTRATION_SUCCESS:
            return {
                ...state,
                isSuccessReg: true,
                isFetching: false
            }
        default:
            return state;
    }
}
