import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";

function* getData({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { firstLoad: true }})
        const data = yield service.getTubi(args);
        yield put({type: actions.GET_SUCCESS, ...data.data});

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { firstLoad: false }})
    }
}

function* getOptions({ args }) {
    try {
        const data = yield service.getOptions();
        yield put({type: actions.GET_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getValues({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { valuesLoading: true }})
        const data = yield service.getRiskValues(args);
        yield put({type: actions.GET_RISK_VALUES_SUCCESS, ...data.data});

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { valuesLoading: false }})
    }
}

function* getPdfRequest({ args }) {
    try {
        const data = yield service.pdfTubi(args);
        console.log(data)
    }catch (e) {
        console.log(e);
    }
}
function* getArticulOptions({ args }) {
    try {
        const data = yield service.getArticulTubi(args);
        yield put({type: actions.GET_ARTICLE_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
export default function* tubiSaga() {
    yield all([
        takeEvery(actions.GET_ARTICLE_REQUEST, getArticulOptions),
        takeEvery(actions.GET_PDF_REQUEST, getPdfRequest),
        takeEvery(actions.GET_RISK_VALUES_REQUEST, getValues),
        takeEvery(actions.GET_REQUEST, getData),
        takeEvery(actions.GET_OPTIONS_REQUEST, getOptions)
    ]);
}
