const actions = {
    GET_REQUEST: 'GET_REQUEST_FILTERS',
    GET_SUCCESS: 'GET_SUCCESS_FILTERS',

    GET_OPTIONS_REQUEST: 'GET_OPTIONS_REQUEST_FIL',
    GET_OPTIONS_SUCCESS: 'GET_OPTIONS_SUCCESS_FIL',

    SET_VALUE: 'SET_VALUE_FIL',

    getOptions: () => ({
        type: actions.GET_OPTIONS_REQUEST
    }),
    getData: (args: object) => ({
        type: actions.GET_REQUEST,
        args
    }),

}

export default actions
