import Api from './api';
// @ts-ignore
import {TDifValuesRequest, TEditMeRequest, TRegistration, TRisk, TTubiPdfRequest} from "../types";
import {hostName} from './../config/config.json';


const Lang  = (data?: object) => {
  const lang = localStorage.getItem('lang');
  return {...data, lang: lang ? lang : 'EN' }
}
export default {
  getFilters: ( data: object) => {
    const api = new Api();
    return api.query('post', 'get-filtri', { data: JSON.stringify(Lang(data))})
  },
  getOptionsFilters: () => {
    const api = new Api();
    return api.query('post', 'get-famiglia',{ data: JSON.stringify({})})
  },
  login: (email: string, password: string) => {
    const api = new Api();
    return api.query('post', `login`, {email, password: btoa(password)})
  },
  logout: () => {
    const api = new Api();
    return api.query('get', 'logout');
  },
  editMe: (data: TEditMeRequest) => {
    const api = new Api();
    return api.query('post', 'update-profile'/*'setting/update'*/, data)
  },
  registration: (data: TRegistration) => {
    const api = new Api();
    return api.query('post', 'registration', data);
  },
  passwordRec: (email: string) => {
    const api = new Api()
    return api.query('post', 'restore-password'/*'password/reset'*/, {email});
  },
  passwordRenew: (new_password: string) => {
    const api = new Api();
    return api.query('post', 'new-password'/*'password/new'*/, { new_password });
  },
  getTubi: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-category'/*'get-tubi-famiglia'*//*'get-tubi-category''tubi-ventilazione/get-categoria'*/, { data: JSON.stringify(data) });
  },
  getArticulTubi: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-article', { data: JSON.stringify(Lang(data)) });
  },
  getDiffusers: (data: object) => {
    const api = new Api();
    return api.query('post', 'diffusori/get-categoria', data)
  },
  getOptions: () => {
    const api = new Api();
    return api.query('post', 'get-tubi-famiglia', { data: JSON.stringify(Lang())})
  },
  getMe: ()=>{
  const api = new Api();
  return api.query('post', 'get-profile',{ lang: 'EN'});
  },
  getRiskValues: (data: TRisk) => {
    const api = new Api();
    return api.query('post', 'get-tubi-valore', { data: JSON.stringify(Lang(data)) });
  },
  pdfTubi: (data: TTubiPdfRequest)=>{
    const api = new Api();
    return api.query('get', 'pdf/tubi-industriali', { params: { ...data } });
  },
  getDataDiff: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-category', { data: JSON.stringify(Lang(data))})
  },
  getOptionsDiff: () => {
    const api = new Api();
    return api.query('post', 'get-famiglia', {data: JSON.stringify(Lang({}))});
  },
  getArticulDiff: (data: {cod_categoria: string, cod_famiglia: string}) =>{
    const api = new Api();
    return api.query('post', 'get-article', {data: JSON.stringify(Lang(data))});
  },
  getOutputDiff: (data: object) => {
    const api = new Api();
    return api.query('post', 'diffusori/get-output', data);
  },
  getValuesDiff: (data: TDifValuesRequest) => {
    const api = new Api({ baseUrl: hostName });
    return api.query('post', '/diffusori/get-values', {data: Lang(data)});
  },
  getDataAsp: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-category', {data: JSON.stringify(Lang(data))});
  },
  getOptionsAsp: () => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-famiglia-options',{data: JSON.stringify(Lang())});
  },
  getTemperatureAsp: () => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-temperatura-options',{data: JSON.stringify(Lang())});
  },
  getPropertyAps: () => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-property-options',{data: JSON.stringify(Lang())});
  },
  getReazioneAsp: () => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-reazione-options',{data: JSON.stringify(Lang())});
  },
  getReazioneValueAsp: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-reazione', {data: JSON.stringify(data)})
  },
  getDiameterOptionsAsp: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-diametro-options', {data: JSON.stringify(Lang(data))})
  },
  getAutoCompleteList: (data: object) => {
    const api = new Api();
    return api.query('post', 'get-tubi-industriali-resistenza', {data: JSON.stringify(Lang(data))})
  },
  getBarTemplate: () => {
    const api = new Api();
    return api.query('get', 'get-tipologia', { params: Lang() })
  },
  getParams: (code: string) => {
    const api = new Api();
    return api.query('get', 'get-diametro', { params: Lang({ code })})
  },

  sendEmail: (data: object) => {
    const api = new Api();
    // @ts-ignore
    const string = Object.keys(data).map(item=> `${item}=${data[item]}`).join('&')
    return api.query('post', `send-email?${string}`, { parametres : JSON.stringify(Lang(data)) })
  },
  getCertification: () => {
    const api = new Api();
    return api.query('get', 'get-certifications', { params: Lang() })
  },
  getModelo: () => {
    const api = new Api();
    return api.query('get', 'get-modello', { params: Lang() });
  }
};
