//import './Components/style.css'
import '../styles/header-style.css';

import React from "react";
import {Button, Drawer, Tabs} from 'antd/lib';
// @ts-ignore
import logo from './Components/logo/logo.png';
// @ts-ignore
import whiteLogo from './Components/logo/whiteLogo.png'
import { MenuOutlined } from '@ant-design/icons';

import {Close, MailIcon, SearchIcon, UserIcon} from "./Components/svg/svgs";
import {useState, useCallback} from "react";
import Search from "antd/es/input/Search";
import {useHistory, Link} from 'react-router-dom';
import localisation from "./localisation";
import {TRootState} from "../redux/store";
import {TAuthState} from "../redux/auth/reducer";
import {useDispatch, useSelector } from 'react-redux';
import actions from "../redux/auth/actions";

const { TabPane } = Tabs;



export default function ({isAuth}: {isAuth: boolean}) {

    const { lang } = useSelector<TRootState, TAuthState>(state => state.auth);
    const dispatch = useDispatch();
    const setLang = useCallback(args => dispatch(actions.setLang(args)),[dispatch]);
    const logout = useCallback( () => dispatch(actions.logout()), [dispatch]);

   /* const [searchVisible, setSearchVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');*/
    const [visibleMenu, setVisibleMenu] = useState(false);
    const history = useHistory();



    const rootUrls = {
        app: '/',
        products: 'https://tecnicasrl.it/prodotti/',
        certifications:'https://tecnicasrl.it/certificazioni/',
        home: 'https://tecnicasrl.it/'
    }


    return <div className={'header-container'}>
        <span className={'header-tabs'}>
            <Link to={'/'}>
                <img src={logo}  className={'header-logo'} alt={'logo'}/>
            </Link>
            {
                !isAuth
                    ?
                    <span className={'header-tabs-position'}>
                        <Tabs  defaultActiveKey={rootUrls.app} defaultValue={rootUrls.app}  onChange={e=> e==='/' ? history.push(e): window.location.href = e} tabBarStyle={{fontSize: '40px'}} style={{marginLeft: '3%', fontSize:'16px', lineHeight:'52px'}} className={'header-tab-menu-selected'} >
                            <TabPane tab={localisation[lang].js.Home} key={rootUrls.home}>{}</TabPane>
                            <TabPane tab={localisation[lang].js.App} key={rootUrls.app}>{}</TabPane>
                            <TabPane tab={localisation[lang].js.Prodotti} key={rootUrls.products}>{}</TabPane>
                            <TabPane tab={localisation[lang].js.Certificazioni} key={rootUrls.certifications}>{}</TabPane>
                        </Tabs>
                    </span>
                    :
                    null
            }
        </span>
        <Button onClick={()=> setVisibleMenu(true)} className={'header-menu-button-burger'} style={{height: '74px',width: '87px', background: '#E31014' ,border:'none', borderRadius:'unset'}}  type={'primary'}  icon={<MenuOutlined  style={{width: '100%',  fontSize: '25px'}}/>}>{}</Button>
        {/*{
            !isAuth
                ?
                <div className={'header-user-menu'} >
                    {!searchVisible
                        ? <Button type={'link'} onClick={()=> {
                            setSearchVisible(true)
                        }} className={'header-menu-button'} style={{color: 'black'}} icon={<SearchIcon/>}>{}</Button>
                        : <Search style={{width: '200px', display:'inline-block', verticalAlign: 'middle'}}/>
                    }
                    <Button type={'link'} className={'header-menu-button'} style={{color: 'black'}} icon={<MailIcon/>}>{}</Button>
                    <Button type={'link'} className={'header-menu-button'} style={{color: 'black'}} icon={<UserIcon/>}>{}</Button>
                </div>
                :
                null
        }*/}

        <Drawer title={<img className={'header-logo'}  src={whiteLogo} alt={'logo'}/>}
                drawerStyle={{background:'#3B383E'}}
                extra={<div>
                        <Button style={{ borderLeft: '1px solid #727272', height: '60px', paddingLeft: '15px', paddingRight: '30px'}}
                                type={'link'} onClick={()=>setVisibleMenu(false)} icon={<Close/>}>{}</Button>
                    </div>}
                headerStyle={{
                    padding: '1px',
                    background: '#3B383E', borderBottom: '1px solid #727272'
                }}
                height={600}
                placement="top"
                onClose={()=>setVisibleMenu(false)}
                closeIcon={<Close/>}
                visible={visibleMenu}>
            <div className={'header-dropdown-menu-container'}>
                <div className={'header-dropdown-menu-list'}>
                    <div className={'header-dropdown-menu-links'}>
                        <Button type={'link'} href={'/'} >{localisation[lang].js.Home}</Button>
                    </div>
                    <div className={'header-dropdown-menu-links'}>
                        <Button type={'link'} href={isAuth ? '/setting' : '/'} >{localisation[lang].js.Profilo}</Button>
                    </div>
                    <div className={'header-dropdown-menu-links'}>
                        <Button type={'link'} href={'/contacts'} >{localisation[lang].js.Contatti}</Button>
                    </div>

                </div><div className={'lang-container'}>
                <div onClick={()=> lang !== 'it' ?   setLang('it') : ''} className={`lang-item ${lang === 'it' ? 'lang-selected' : ''}`}>
                    <img src={'/it.png'}/>ITA
                </div>
                <div onClick={()=>  lang !== 'en' ?   setLang('en') : ''} className={`lang-item ${lang === 'en' ? 'lang-selected' : ''}`}>
                    <img src={'/en.png'}/>ENG
                </div>
            </div>
            </div>

            <div className={'header-dropdown-menu-links'}>
                {
                    isAuth
                        ?
                        <Button type={'link'} onClick={()=> logout()} >{localisation[lang].js.Esci}</Button>
                        :
                        null
                }

            </div>
        </Drawer>
    </div>
}
