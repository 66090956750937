import actions from './actions';
import {IAction, TFilters} from "../../types";

type TSert = {
    title: string,
    descriptions: string,
    webLink: string,
    url: string
}
const initialState = {
    cert: [],
    isEmailSanded: false,
    firstLoad: false,
    isFetching: false
};
export type TInfoState = {
    cert: TSert[],
    isFetching: boolean,
    firstLoad: boolean,
    isEmailSanded: boolean
    options: {
        label: string,
        value: string
    }[]
}
export default function info(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {

        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            }
        case actions.GET_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                cert: Array.isArray(data) ? data.map(item=> {return { title: item.Certificazione, descriptions: item.NoteCertificazione, webLink: item.URLWeb, url: `data:image/png;base64, ${item.ImgCertificazione}`}}): []
            }
        case actions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                isEmailSanded: true
            }
        default:
            return state;
    }
}
