import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";

function* getData({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { firstLoad: true }})
        const data = yield service.getDataAsp(args);
        yield put({type: actions.GET_SUCCESS, ...data.data});

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { firstLoad: false }})
    }
}

function* getOptionsFagm({ args }) {
    try {
        const data = yield service.getOptionsAsp();
        yield put({type: actions.GET_FAGMILIA_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getOptTemp({ args }) {
    try {
        const data = yield service.getTemperatureAsp();
        yield put({type: actions.GET_TEMPERATURE_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getOptReaz({ args }) {
    try {
        const data = yield service.getReazioneAsp();
        yield put({type: actions.GET_REAZIONE_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getOptProp({ args }) {
    try {
        const data = yield service.getPropertyAps();
        yield put({type: actions.GET_PROPERTY_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getAutoCompleteList({ args }) {
    try {

        const data = yield service.getAutoCompleteList(args);
        yield put({type: actions.GET_AUTOCOMPLETE_LIST_SUCCESS, ...data.data});

    }catch (e) {
        console.log(e);
    }
}
function* getDiametrOpt({ args }) {
    try {
        const data = yield service.getDiameterOptionsAsp(args);
        yield put({type: actions.GET_DIAMETR_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
function* getReazioneValues({ args }) {
    try {
        const data = yield service.getReazioneValueAsp(args);
        yield put({ type: actions.GET_REAZION_VALUE_SUCCESS, ...data.data })
    }catch (e) {
        console.log(e)
    }
}
function* getBarInterface({ args }) {
    try {

        const data = yield service.getBarTemplate();
        //console.log(data)
        yield put({type: actions.GET_BAR_VALUES_SUCCESS, data:data.data});

    }catch (e) {
        console.log(e);
    }
}
function* getModelo() {
    try {
        const data = yield service.getModelo();
        yield put({type: actions.GET_MODELO_SUCCESS, data: data.data });
    }catch (e) {
        console.error(e);
    }
}
export default function* aspSaga() {
    yield all([
        takeEvery(actions.GET_MODELO_REQUEST, getModelo),
        takeEvery(actions.GET_BAR_VALUES_REQUES, getBarInterface),
        takeEvery(actions.GET_REAZION_VALUE_REQUEST, getReazioneValues),
        takeEvery(actions.GET_DIAMETR_OPTIONS_REQUEST, getDiametrOpt),
        takeEvery(actions.GET_AUTOCOMPLETE_LIST_REQUEST, getAutoCompleteList),
        takeEvery(actions.GET_REQUEST, getData),
        takeEvery(actions.GET_PROPERTY_OPTIONS_REQUEST, getOptProp),
        takeEvery(actions.GET_FAGMILIA_OPTIONS_REQUEST, getOptionsFagm),
        takeEvery(actions.GET_TEMPERATURE_OPTIONS_REQUEST, getOptTemp),
        takeEvery(actions.GET_REAZIONE_OPTIONS_REQUEST, getOptReaz),
    ]);
}
