import actions from './actions';
import {IAction, TFilters} from "../../types";


const initialState = {
    data: [],
    firstLoad: false
};
export type TFiltersState = {
    data: TFilters[],
    firstLoad: boolean,
    options: {
        label: string,
        value: string
    }[]
}
export default function filters(state = initialState, action: IAction) {
    const { type, data } = action;
    switch (type) {
        case actions.SET_VALUE:
            return {
                ...state,
                ...data
            }
        case actions.GET_OPTIONS_SUCCESS:
            return {
                ...state,
                options: data
            }
        case actions.GET_SUCCESS:
            return {
                ...state,
                firstLoad: false,
                data: Array.isArray(data) ? data.map(item=> { return {...item, img: `data:image/png;base64, ${item.img}`}}) : []
            };
        default:
            return state;
    }
}
