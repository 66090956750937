export default {
    SaveBtn : 'Save',
    ClickHere : 'Click here',
    ChangePass : 'Do you want to change your password?',
    ForgotPass : 'Forgot Password?',
    Name : 'Name',
    Surname : 'Surname',
    Address : 'Address',
    Company : 'Company',
    City : 'City',
    SalesOffice: 'Sales office Italy',
    ContactsTubi: 'Flexible Hoses Division',
    ContactsFiltriDiff: 'Filters and Diffusers Division',
    ComOffice: 'Commercial office',
    FSalesOffice: 'Foreign sales office',
    Abroad: 'Estero',
    Italy: 'Italy',
    PDep: 'Purchasing department',
    Admin: 'Administration',
    Country : 'Country',
    LogOut : 'LogOut',
    Back : 'Back',
    Close : 'Close',
    Registration : 'Registration',
    GetAccess : 'Login',
    CreateAcc : 'Create Account',
    PassSent : 'Instructions were sent on your email. Follow instructions to change your password.',
    PassChanged : 'Your password have been successfully changed.',
    Confirm : 'Confirm password',
    OkPass : 'Passwords match',
    DiffPass : 'Passwords do not match' ,
    EmptyFields : 'All fields must be filled',
    Logout : 'Logout',
    RenewSuccess : 'Registration was successful!',
    SelectChar : 'Select the characteristics of the product you are looking for',
    SelectCert : 'Select product certifications',
    CertTitle : 'Green building certifications',
    CertText : 'For further information on specific categories of contribution, contact Tecnica srl',
    SanificazioneFiltri : 'Sanitized Filters',
    SanificazioneTubi : 'Sanitized Hoses',
    Risultati : 'Results',
    CercaInput : 'Search by code or description..',
    Famiglia : 'Family',
    AddProd : 'Product that may interest you',
    SelChar : 'Select the characteristics of the product',
    Dimensioni : 'Nominal size',
    //Portata : 'Flow Rate required [m3/h]',
    Diff : 'Temperature Differential',
    Velocit : 'Terminal launch speed [m/s]',
    Altezza : 'Installation height [m]',
    Vedi : 'SEE DETAILS',
    LoadingChar : 'Loading characteristics..',
    Caratteristiche : 'Characteristic',
    Codice : 'Code',
    Descrizione : 'Description',
    Sanificazione : 'Sanitization',
    Diametro : 'Internal Diameter [mm]',
    Velocita : 'Air Flow Speed [m/s]',
    Curva_first_part : '90',
    Curva_second_part :  'bend [R/D]',
    Curva2_first_part : '45',
    AdvFiltri : 'Condensation risk verification',
    TempFlusso : 'Air Flow Temperature' ,
    TempAmbiente :' Room Temperature ',
    Umidita : 'Relative Humidity [%]',
    DataList: 'Data sheet',
    VelocitaName: 'Air Flow Speed',
    Portata : 'Flow Rate',
    PerditaLineare : 'Linear pressure drop',
    PerditaCurva45 : '45° elbow – Pressure drop',
    PerditaCurva90 : '90° elbow – Pressure drop',
    Trasmittanza : 'Thermal Transmittance',
    Spessore : 'Insulation thickness [mm]',
    goBack: 'Back',
    passwordResetPageTitle: 'Password recovery',
    passwordResetFormTitle: 'Lost your password? or',
    passwordResetLink: 'Back',
    Misura:'U. MEASURE',
    UMisura: 'MEASURE',
    isRequired: ' is required!',

    wrong:'Password or email is wrong!',
    profileFormTitle: 'Vuoi cambiare la password?',
    profileFormLink: 'Click here',

    passwordRenewTitle: 'Password renew',
    passwordRenewTitleOfPage: 'Are you want edit your profile ? ',

    selectOptions: 'All options',
    selectCert: 'All certifications',
    selectTemperature: 'Select temperature',
    selectSubstances: 'All substances',
    selectProperties: 'All properties',
    selectMultiple: 'All selections',

    SendEmail: 'SEND FORM BY EMAIL',
    SendEmailDescription: 'Enter the email address on which you want to receive the card ',
    SendEmailConfirmBtn: 'SEND FORM BY EMAIL',

    Filtra: 'Filters',
    pasRes: 'Forgot password ?',
    Submit: 'SUBMIT',
    logOr: 'Log into your account or',
    registrationFormTitle: 'Please register or',
    registrationFromLink: 'Login to your account',
    registrationRef: 'Register now',
    Verifica : 'Condensation risk verification',
    TemperaturaEst : 'Pipe wall external temperature [Tpe]',
    TemperaturaMin : 'Dew temperature [Tdew]',
    TemperaturaMax :' Dripping temperature [Tdrp]',
    SelezionaCertificazione: 'Select the Green Building certification',
    Gocciolamento : 'Drip risk',
    Condensa : 'Condensation risk',
    NoCondensa : 'No condensation',
    Certificazioni_menu:'Certificazioni',
    Prodotti: 'Products',
    Profilo:'Profile',
    Contatti:'Contacts',
    Esci:'Logout',
    DepressioneLabel:'Depression',
    Home: 'Home',
    Fumi: 'Industrial fumes',
    PressioneLabel:'Pressure',
    Abrasione:'Abrasion',
    App: 'App Tecnica',
    Tutte: 'All selections',
    SostanzaTrattata : 'Medium',
    Temperatura : 'Temperature',
    Certificazioni : 'Certifications',
    Propriet : 'Properties',
    Reazione : 'Fire Reaction',
    Facoltativo : '(* optional)',
    GreenBuilding : 'Green Building',

    DiametroEmpty : 'Select a diameter to see the technical data',
    DiametroInt :' Selected inside diameter',
    TempMin : 'Minimum operating temperature',
    TempMax : 'Maximum operating temperature',
    PiccoMax : 'Maximum peak of temperature',

    DataTech : 'Technical Data',
    Resistenza : 'Chemical Resistance',
    Pressione : 'Operating Pressure',
    Depressione : 'Operating Vacuum',
    Curvatura : 'Inside Curvature Radius',
    Peso : 'Weight',
    Compressione : 'Axial Compressibility',

    Norma : 'Norm / Class',
    LoadingResist : 'Loading resistance..',
    SearchResist : 'Look for the value of ...',

    OldPassword : 'Old password',
    NewPassword : 'New password',

}