// @ts-ignore
import {TRequest, TTubiPdfRequest} from "../../types";

const actions = {
    GET_REQUEST: 'GET_TUBI_REQUEST',
    GET_SUCCESS: 'GET_TUBI_SUCCESS',

    GET_OPTIONS_REQUEST: 'GET_TUBI_OPTIONS_REQUEST',
    GET_OPTIONS_SUCCESS: 'GET_TUBI_OPTIONS_SUCCESS',

    GET_ARTICLE_REQUEST: 'GET_ARTICLE_REQUEST_TUBI',
    GET_ARTICLE_SUCCESS: 'GET_ARTICLE_SUCCESS_TUBI',

    GET_RISK_VALUES_REQUEST: 'GET_RISK_VALUES_REQUEST',
    GET_RISK_VALUES_SUCCESS: 'GET_RISK_VALUES_SUCCESS',

    GET_PDF_REQUEST: 'GET_PDF_REQUEST',

    SET_VALUE: "SET_VALUE_TUBI",

    getArticul: (args: {cod_categoria: string, cod_famiglia?: string}) => ({
        type: actions.GET_ARTICLE_REQUEST,
        args
    }),
    getOptions: () => ({
        type: actions.GET_OPTIONS_REQUEST
    }),
    getPdf: (args: TTubiPdfRequest)=>({
        type: actions.GET_PDF_REQUEST,
        args
    }),
    getValues: (args: TRequest) => ({
        type: actions.GET_RISK_VALUES_REQUEST,
        args
    }),
    getData: (args: object) => ({
        type: actions.GET_REQUEST,
        args
    }),

}

export default actions
