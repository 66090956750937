import  {lazy} from 'react';
import {IRoute} from "../types";


function possibleRoutes(isAuth: boolean ) : IRoute[] {

  if(isAuth)
    return [
      {
        path:'password/renew',
        component: lazy(()=>import('./Forms/PasswordRenew'))
      },
      {
        path:'setting',
        component: lazy(()=> import('./Forms/Profile'))
      },
      {
        path: 'tubi-industriali',
        component: lazy(()=> import('./Aspirazione'))
      },
      {
        path: 'filtri',
        component: lazy(()=> import('./Filters'))
      },
      {
        path: 'diffusori',
        component: lazy(()=> import('./Diffusers'))
      },
      {
        path:'tubi-ventilazione',
        component: lazy(()=> import('./Tubi'))
      },
      {
        path: 'contacts',
        component: lazy(()=> import('./Contacts'))
      },
      {
        path: '',
        component: lazy(()=> import('./Home/index'))
      }
    ]
  else
    return [
      {
        path: 'contacts',
        component: lazy(()=> import('./Contacts'))
      },
     /* {
        path: 'home',
        component: lazy(()=> import('./Home/index'))
      },*/
      {
        path:'pass-rec',
        component: lazy(()=> import('./Forms/PassRec'))
      },
      {
        path:'',
        component: lazy(()=> import('./Forms'))
      },
      {
        path:'registration',
        component: lazy(()=> import('./Forms/Registration')),
      }
    ]
}

export default possibleRoutes;
