import React from 'react';
import {Provider} from 'react-redux'
import store from "./redux/store";
import {BrowserRouter as Router} from "react-router-dom";
import LayoutContainer from "./Pages/LayoutContainer";
import 'antd/dist/antd.css';
import './styles/App.css';
import ErrorHandler from "./Pages/Error";

function App() {
  return (
      <ErrorHandler>
          <Provider store={store}>
              <Router>
                  <LayoutContainer />
              </Router>
          </Provider>
      </ErrorHandler>
  );
}

export default App;
