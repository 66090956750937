import {all, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import service from "../../service/service";

function* getData({ args }) {
    try {

        yield put({type: actions.SET_VALUE, data: { firstLoad: true }})
        const data = yield service.getFilters(args);
        yield put({type: actions.GET_SUCCESS, ...data.data});

    }catch (e) {
        yield put({type: actions.SET_VALUE, data: { firstLoad: false }})
    }
}

function* getOptions({ args }) {
    try {
        const data = yield service.getOptionsFilters();
        console.log(data)
        yield put({type: actions.GET_OPTIONS_SUCCESS, ...data.data})
    }catch (e) {
        console.log(e);
    }
}
export default function* filtersSaga() {
    yield all([
        takeEvery(actions.GET_REQUEST, getData),
        takeEvery(actions.GET_OPTIONS_REQUEST, getOptions)
    ]);
}
