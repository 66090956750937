import { combineReducers } from 'redux';
import auth from "./auth/reducer";
import tubi from "./tubi/reducer";
import dif from "./diffusers/reducer";
import filters from "./filters/reducer";
import aspiration from "./аspiration/reducer";
import info from "./info/reducer";


export default combineReducers({
    auth,
    dif,
    tubi,
    filters,
    aspiration,
    info
});
