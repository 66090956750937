import axios, { Axios} from 'axios';
import config from './../config/config.json'


const apiUrl = config.apiUrl;


type method = 'get' | 'post' | 'put' | 'patch' | 'delete'

export default class Api {
  private axios : Axios;
  constructor({
    baseUrl = apiUrl,
    extraHeaders = {}
  } = {}) {
    const headers = {
      'Accept': '*/*',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      ...extraHeaders
    };

    this.axios = axios.create({
      baseURL: `${baseUrl}/`,
      headers
    });

    this.axios.interceptors.response.use(
        function(response) {
          return Promise.resolve(response);
        },
        function(error) {
          const errorsDetails = `${error}`;
          return Promise.reject(new Error(errorsDetails));
        }
      );
  }

  query(method : method, url : string, data = {}) {
    return this.axios[method](url, data);
  }
}
